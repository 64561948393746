import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { User } from '@shared/models/user.model';
import { IAsset } from '@shared/interfaces/asset.interface';
import { Asset } from './asset.model';
import { ICompany } from '@shared/interfaces/company.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IRecurringTask } from '@shared/interfaces/recurring-task.interface';
import { getConvertedDate } from '@shared/helpers/timezone.helper';
import moment from 'moment';
import { IAddress } from '@shared/interfaces/address.interface';
import { ILibrary } from '@shared/interfaces/library.interface';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';

export class RecurringTask implements IRecurringTask {
  id: string;
  subject: string;
  asset?: Partial<IAsset>[];
  assetGroups?: Partial<IAssetGroup>[];
  issue?: Partial<any>;
  company: Partial<ICompany>;
  createdBy?: Partial<IUser>;
  subLocation: Partial<ILocation>;
  recurringTask?: Partial<IRecurringTask>;
  createdAt: Date;
  pictures?: IPicture[];
  documents?: ILibrary[];
  checklistId?: Partial<IChecklist>;
  priority: string;
  description: string;
  startDateTime?: string; //ex: '1430' = 14h30
  dueDateTime?: string; //ex: '1430' = 14h30
  status?: string;
  recurrence?: string;
  weeklyDays?: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  months?: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  monthlyDay?: number;
  yearlyRepeat?: number; //every 1, 2, 3 years
  unit?: string;
  userType?: string;
  notes: string;
  user: Partial<IUser>;
  startDate: number;
  dueDate: number;
  isAllowSubmissionAfterDue: boolean;
  isDeleted?: boolean;
  isPaused?: boolean;
  address?: IAddress;
  isOverdue?: boolean;
  customizeDates?: boolean;
  constructor(partial: Partial<IRecurringTask>) {
    Object.assign(this, {
      ...partial,
      user: partial.user ? new User(partial.user) : partial.user,
      subLocation: partial.subLocation
        ? new Location(partial.subLocation)
        : partial.subLocation
    });
  }

  get getStartDate() {
    if (!this.startDate) return ''
    return getConvertedDate(moment(new Date(this.startDate).getTime() * 1000), this.address[0].lat, this.address[0].lng)
  }

  get getCreatedAt() {
    return getConvertedDate(moment(new Date(this.createdAt)), this.address[0].lat, this.address[0].lng)
  }

  get getDueDate() {
    if (!this.dueDate || !this.customizeDates) return ''
    return getConvertedDate(moment(new Date(this.dueDate).getTime() * 1000), this.address[0].lat, this.address[0].lng)
  }

  get getRecurrence() {
    let obj = {
      msg: this.recurrence,
      isOverdue: false
    };

    if (this.isOverdue) {
      obj.isOverdue = true;
      obj.msg = `${this.recurrence} <span container="body" ><i class="lnr lnr-notification text-danger flash"></i></span>`;
    }
    return obj;
  }

  get pillsPriority(): any {
    let label;
    let className;

    switch (this.priority) {
      case 'MEDIUM':
        label = 'Medium';
        className = 'ohq-badge-warning';
        break;

      case 'HIGH':
        label = 'High';
        className = 'ohq-badge-danger';
        break;

      case 'LOW':
      default:
        label = 'Low';
        className = 'ohq-badge-light text-dark';
        break;
    }

    return {
      label,
      class: className
    };
  }

}
