import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IDocument } from '@shared/interfaces/document.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IMeter } from '@shared/interfaces/meter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Document } from '@shared/models/documents';
import { Meter } from '@shared/models/meter.model';
import { MeterSerializer } from '@shared/serializers/meters.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnergyStarMetersService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/energystar`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) { }

  public find(id: string): Observable<IMeter> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: IMeter) => new Meter(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public checkEnergyStarConnection(): Observable<boolean> {
    return this._http.get<boolean>(`${this.endpoint}/connection-status`).pipe(
      catchError(() => of(false))
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IMeter[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((l: IMeter) => new Meter(l))
      ),
      catchError(errorHandler)
    );
  }

  public getProperties(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IMeter[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this._commonEnvironments.API_ENDPOINT}/energystar/properties`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      // map((res: any) =>
      //   deserializer.deserialize(res).map((l: IMeter) => new Meter(l))
      // ),
      catchError(errorHandler)
    );
  }

  public getCompanies(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<any[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this._commonEnvironments.API_ENDPOINT}/energystar/admin/properties`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      // map((res: any) =>
      //   deserializer.deserialize(res).map((l: IMeter) => new Meter(l))
      // ),
      catchError(errorHandler)
    );
  }
  public findAllMetersByProperties(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IMeter[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}/meters`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      // map((res: any) =>
      //   deserializer.deserialize(res).map((l: IMeter) => new Meter(l))
      // ),
      catchError(errorHandler)
    );
  }

  public findAllPropertiesFromCompanyUser(): Observable<string> {
    return this._http.get(`${this.endpoint}/properties`).pipe(
      map((res: any) => res
        // deserializer.deserialize(res).map((m: IMeter) => new Meter(m))
      ),
      catchError(errorHandler)
    );
  }

  public findAllMetersByPropertyId(id: string): Observable<IMeter[]> {
    return this._http.get(`${this.endpoint}/${id}/meters`).pipe(
      map((res: any) => res
        // deserializer.deserialize(res).map((m: IMeter) => new Meter(m))
      ),
      catchError(errorHandler)
    );
  }

  public post(l: IMeter): Observable<IMeter> {
    const data = MeterSerializer.serialize(l);
    return this._http.post(this.endpoint, data).pipe(
      map((res: IMeter) => new Meter(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }


  public getMeterInfo(id: string): Observable<IMeter> {
    return this._http.get(`${this.endpoint}/${id}/meter-info`).pipe(
      map((res: IMeter) => new Meter(res)),
      catchError(errorHandler)
    );
  }

}
