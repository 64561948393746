import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';
import { assets } from '@shared/serializers/assets.serializer';
import { companies } from '@shared/serializers/companies.serializer';
import { checklists } from '@shared/serializers/checklists.serializer';
import { tasks } from '@shared/serializers/tasks.serializer';
import { assetGroups } from './asset-groups.serializer';

export const recurringTasks = {
  type: 'recurringTask',
  attributes: [
    'subLocation',
    'user',
    'subject',
    'recurrence',
    'status',
    'startDate',
    'dueDate',
    'isAllowSubmissionAfterDue',
    'isPaused',
    'isDeleted',
    'createdAt',
    'updatedAt',
    'asset',
    'company',
    'priority',
    'description',
    'startDateTime',
    'dueDateTime',
    'unit',
    'notes',
    'userType',
    'address',
    'yearlyRepeat',
    'monthlyDay',
    'weeklyDays',
    'checklistId',
    'pictures',
    'documents',
    'createdBy',
    'hasStartDate',
    'hasStartTime',
    'hasDueDate',
    'hasDueTime',
    'customizeDates',
    'weeklyDays',
    'monthlyDays',
    'yearlyMonths',
    'lastTask',
    'isOverdue',
    'scheduledDates',
    'assetGroups'
  ],
  subLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: true
  },
  lastTask: {
    ref: 'id',
    attributes: ['company',
      'user',
      'createdBy',
      'location',
      'asset',
      'recurringTask',
      'pictures',
      'documents',
      'parent',
      'task',
      'description',
      'priority',
      'unit',
      'status',
      'recurrence',
      'userType',
      'isDeleted',
      'isSendEmailSelected',
      'notes',
      'completedAt',
      'dueDate',
      'startDate',
      'address',
      'checklistId',
      'hasStartDate',
      'hasStartTime',
      'hasDueDate',
      'hasDueTime',
      'customizeDates',
      'weeklyDays',
      'monthlyDays',
      'yearlyMonths',
      'isWeekendExcluded',
      'createdAt',
      'updatedAt'],
    included: true
  },
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: true
  },
  asset: [{
    ref: '_id',
    attributes: assets.attributes,
    included: true,
    multiple: true
  }],
  // issues: {
  //   ref: 'id',
  //   attributes: issues.attributes
  // },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: true
  },
  checklistId: {
    ref: 'id',
    attributes: checklists.attributes,
    included: true
  },
  createdBy: {
    ref: 'id',
    attributes: users.attributes,
    included: true
  },
  assetGroups: [{
    ref: 'id',
    attributes: assetGroups.attributes,
    included: false
  }]
};

export const RecurringTaskSerializer = getSerializer(recurringTasks);
