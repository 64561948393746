import { IAssetGroup } from '@shared/interfaces/asset-group.interface';

export class AssetsGroup implements IAssetGroup {
  id?: string;
  name: string;
  description?: string;
  company: string;
  isDeleted?: boolean;

  constructor(partial: Partial<IAssetGroup>) {
    Object.assign(this, partial);
  }

}
