import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAddress } from '@shared/interfaces/address.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IEnergyStarMeter } from '@shared/interfaces/energy-star-meter.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Company } from '@shared/models/company.model';
import { Employee } from '@shared/models/employee.model';
import { Location } from '@shared/models/location.model';
import { AddressesService } from '@shared/services/addresses.service';
import { CompaniesService } from '@shared/services/companies.service';
import { EmployeesService } from '@shared/services/employees.service';
import { EnergyStarMetersService } from '@shared/services/energy-star-meters.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationEnergyStarService } from '@shared/services/pagination/pagination.energyStar.service';
import { PaginationLocationsService } from '@shared/services/pagination/pagination.locations.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSelectOption } from 'ngx-select-ex';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-locations',
  templateUrl: './modals.locations.component.html',
  styleUrls: ['./modals.locations.component.scss']
})
export class ModalsLocationsComponent
  extends ModalDirective<ILocation>
  implements OnInit {
  @ViewChild('complete', { static: true })
  public complete: GooglePlaceDirective;

  @Input() public openModal: Subject<ILocation>;

  public defaultCompanyValue: any = '';
  public defaultUserValue: any = '';
  public notes: string;

  public modules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: [0, 1]
    }
  ];

  public options: any = {
    types: []
  };

  public title = String('MODALS.LOCATIONS.PARENT.ADD.TITLE');
  public description = String('MODALS.LOCATIONS.PARENT.ADD.DESCRIPTION');

  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public isEditing = Boolean(false);
  public managerList: any[] = [];
  public energyStarProperties: IEnergyStarMeter[] = [];
  public energyStarConnection = false;

  public entry: FormGroup;
  public role: number;
  public isAdmin: boolean;
  public isOnsiteAdmin: boolean;
  public isSubLocation = Boolean(false);
  public isLoading = Boolean(false);
  public isLoadingEnergyStarLocations = Boolean(false);
  public files: NgxFileDropEntry[] = [];
  public items: any[] = [];
  public isUploading = Boolean(false);
  public file: File;
  public id: string;
  // public provinces: {
  //   name: string;
  //   code: string;
  // }[] = provinces;

  public config: any = {
    class: 'modal-lg'
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _companies: CompaniesService,
    public readonly _locations: LocationsService,
    public readonly _employees: EmployeesService,
    private readonly _addresses: AddressesService,
    private readonly _energyStarMeters: EnergyStarMetersService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _toasts: ToastService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _router: Router,
    private readonly _paginationLocations: PaginationLocationsService,
    private readonly _paginationEnergyStar: PaginationEnergyStarService,
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.checkConnection();
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    const role = Number(payload.role);
    const employeeRole = Number(payload.employeeRole);
    this.isAdmin = Boolean(employeeRole === 0);
    this.isOnsiteAdmin = Boolean(role === 0);
    this.isLoading = true;
    this.deleteKey = null;
    this._employees.get(1, [
      {
        key: 'role',
        value: [0, 1]
      },
      {
        key: 'limit',
        value: 300
      }
    ]).subscribe(res => {
      this.isLoading = false;
      this.managerList = res.map(res2 => res2.user);
      this.createForm();
      this.openModal.subscribe((l: ILocation) => {
        this.items = [];
        this.entry.patchValue({
          fileNames: [],
          files: []
        });
        if (l) {
          if (l.parent) {
            this.isSubLocation = true;
            this.title = 'MODALS.LOCATIONS.SUB_LOCATIONS.ADD.TITLE';
            this.description = 'MODALS.LOCATIONS.SUB_LOCATIONS.ADD.DESCRIPTION';

          } else {
            this.isSubLocation = false;
            this.title = 'MODALS.LOCATIONS.PARENT.ADD.TITLE';
            this.description = 'MODALS.LOCATIONS.PARENT.ADD.DESCRIPTION';
          }

          this.getAllProperties();
          if (l.id) {
            this.id = l.id;
            const url = `GET /tasks/${this.id}/documents`;
            this.items = [];
            this._locations.getDocuments(this.id).subscribe(
              (docs: IDocument[]) => {
                this._logger.info(this.constructorName, url, docs);
                this.items = docs;
                this.isLoading = false;
                this.isUploading = false;
              },
              (err: any) => {
                this._logger.error(this.constructorName, url, err);
                this.isLoading = false;
              }
            );
            this.isEditing = true;
            if (l.parent) {
              this.title = 'MODALS.LOCATIONS.SUB_LOCATIONS.EDIT.TITLE';
              this.description =
                'MODALS.LOCATIONS.SUB_LOCATIONS.EDIT.DESCRIPTION';
              this.deleteKey = 'BUTTONS.DELETE';

              this.isSubLocation = true;
            } else {
              this.title = 'MODALS.LOCATIONS.PARENT.EDIT.TITLE';
              this.description = 'MODALS.LOCATIONS.PARENT.EDIT.DESCRIPTION';
            }
          } else {
            this.isEditing = false;
          }
          let addressName = l?.address?.name ? l?.address?.name : '';
          addressName = addressName + ' ' + l?.address?.city + ', ' + l?.address?.province + ', ' + l?.address?.country;
          this.entry.patchValue({
            ...l,
            secondaryManagers: l.secondaryManagers?.map((item: any) => item.id),
            subLocationManagers: l.subLocationManagers?.map((item: any) => item.id),
            energyStarPropertyId: l.energyStarPropertyId,
            complete: l?.label,
            parent: l?.parent || {},
            user: l?.user || {},
            fileNames: [],
            company: l?.company || {},
            address: {
              ...(l?.address || {}),
              location: l?.id,
              complete: l?.id ? addressName : ''
            }
          });

          if (l.id) {
            this.defaultCompanyValue = l.company?.name;
            if (l.user) {
              this.defaultUserValue = l.user?.email;
              this.entry.get('user').patchValue({ id: l.user.id });
            }
          }

          if (!l?.parent && !this.isEditing && !this.isAdmin) {
            this.entry.patchValue({
              user: payload
            });
          }
        } else {
          this.isSubLocation = false;
        }

        this.config.class = this.isAdmin && !this.isSubLocation ? 'modal-lg' : '';

        this.setValidators();
      });
    }, err => {
      this.isLoading = false;
      console.log('err: ', err);
    });
  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.files = files;
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
          if (this.entry.get('fileNames').value) {
            this.entry.patchValue({
              fileNames: [...this.entry.get('fileNames').value, file.name]
            });
          } else {
            this.entry.patchValue({
              fileNames: [file.name]
            });
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  public getAllProperties() {
    this.isLoadingEnergyStarLocations = true;
    const url = `GET /energystar/companies/properties`;
    this._energyStarMeters.getProperties().subscribe(
      (res: IEnergyStarMeter[]) => {
        this._logger.info(this.constructorName, url, res);

        this.energyStarProperties = [{ label: 'N/A', propertyId: '' }, ...res];
        this.isLoadingEnergyStarLocations = false;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoadingEnergyStarLocations = false;
      }
    );

  }

  public checkConnection() {
    const url = `GET /energystar/connection-status`;
    this._energyStarMeters.checkEnergyStarConnection().subscribe(
      (res: boolean) => {
        this._logger.info(this.constructorName, url, res);
        this.energyStarConnection = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );

  }

  public handleAddressChange(place: Address) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const address: any = {
      lat,
      lng
    };
    place.address_components.map((p) => {
      p.types.map((t: any) => {
        switch (t) {
          case 'street_number':
            address.unit = p.long_name;
            break;

          case 'route':
            address.line1 = p.long_name;
            break;

          case 'postal_code':
            address.postalCode = p.long_name;
            break;

          case 'locality':
            address.city = p.long_name;
            break;

          case 'administrative_area_level_1':
            address.province = p.short_name;
            break;

          case 'country':
            address.country = p.short_name;
            break;
        }
      });
    });

    this.entry.patchValue({
      address: {
        ...address,
        name: place.name
      }
    });
  }

  public whenModalClose(type: string): void {
    this.defaultCompanyValue = this.defaultUserValue = '';
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /locations/${id}`;

    this._locations.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /locations`;
      const location = new Location({
        label: value.label,
        ageOfRoof: value?.ageOfRoof || '',
        company: value.company,
        user: value.user,
        parent: value.parent,
        notes: value.notes,
        secondaryManagers: value.secondaryManagers?.map((id: string) => id),
        subLocationManagers: value.subLocationManagers?.map((id: string) => id),
        energyStarPropertyId: value?.energyStarPropertyId
      });

      if (value.id) {
        method = 'patch';
        location.id = value.id;
        url = `${method.toUpperCase()} /locations/${value.id}`;
      }

      this._locations[method](location).subscribe(
        (res: ILocation) => {
          this._logger.info(this.constructorName, url, res);
          this._paginationLocations.reloadPage(
            this._paginationLocations.pagination.page
          );
          this._paginationEnergyStar.reloadPage(1);

          this.id = res.id;
          this.isUploading = true;
          for (const f of this.files) {
            if (f.fileEntry.isFile) {
              const url2 = `POST /tasks/${this.id}/documents`;
              const filters: any = [
                {
                  key: 'ownerId',
                  value: this.id
                },
                {
                  key: 'ownerType',
                  value: 'Location'
                },
                {
                  key: 'type',
                  value: 'picture'
                }
              ];
              const fileEntry = f.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                this._locations.postDocument(file, filters).subscribe(
                  (res2: IDocument) => {
                    this._logger.info(this.constructorName, url2, res2);
                    if (this.entry.get('fileNames').value) {
                      this.entry.patchValue({
                        fileNames: [...this.entry.get('fileNames').value, file.name]
                      });
                    } else {
                      this.entry.patchValue({
                        fileNames: [file.name]
                      });
                    }
                    if (this.entry.get('files').value) {
                      this.entry.patchValue({
                        files: [...this.entry.get('files').value, res2.id]
                      });
                    } else {
                      this.entry.patchValue({
                        files: [res2.id]
                      });
                    }
                    this.isUploading = false;
                  },
                  (err: Error) => {
                    this.resetModal();
                    this.isUploading = false;
                    this._logger.error(this.constructorName, url, err);
                    this._toasts.error('Upload failed');
                  }
                );
              });
            }
          }

          if (this.isSubLocation) {
            this.resetModal();
            return this.entry.enable();
          } else {
            this.entry.patchValue({
              address: {
                location: res.id
              }
            });

            this.submitAddress(this.entry.get('address').value);
          }
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;

          if (errors) {
            this.errors = errors;
          }

          this.entry.enable();
        }
      );
    }
  }

  public onMatOptionSelect(e: any): void {
    let type = 'user';

    let id = e.id;
    if (e instanceof Location) {
      type = 'parent';
    } else if (e instanceof Company) {
      type = 'company';
    } else if (e instanceof Employee) {
      type = 'user';
      id = e.user.id;
    }

    this.entry.get(type).patchValue({
      id
    });
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Location) {
      return e.address.line1;
    } else if (e instanceof Company) {
      const index = this.employeesFilters.findIndex(
        (ef) => ef.key === 'company'
      );

      if (index < 0) {
        this.employeesFilters.push({
          key: 'company',
          value: e.id
        });
      } else {
        this.employeesFilters[index].value = e.id;
      }

      return e.name;
    } else if (e instanceof Employee) {
      return e ? `(${e.email}) ${e.fullName}` : null;
    }
  }

  public onDismiss() {
    this.errors = [];

    this.entityForm?.reset();
    this.setDefaultValues();
  }

  protected createForm() {
    this.entry = this._fb.group({
      files: [''],
      fileNames: [''],
      id: [''],
      label: ['', [Validators.required]],
      notes: [''],
      ageOfRoof: ['', []],
      company: this._fb.group({
        id: ['', [Validators.required]]
      }),
      parent: this._fb.group({
        id: ['', [Validators.required]]
      }),
      user: this._fb.group({
        id: ['', [Validators.required]]
      }),
      secondaryManagers: ['', []],
      subLocationManagers: ['', []],
      energyStarPropertyId: [''],
      address: this._fb.group({
        id: [''],
        name: [''],
        location: [''],
        complete: [''],
        unit: [''],
        line1: [''],
        city: [''],
        postalCode: [''],
        province: [''],
        country: [''],
        lat: [0],
        lng: [0]
      })
    });
  }

  private submitAddress(value: IAddress) {
    let method = 'post';
    let url = `${method.toUpperCase()} /addresses`;

    if (!!value.id) {
      method = 'patch';
      url = `${method.toUpperCase()} /addresses/${value.id}`;
    }

    this._addresses[method](value).subscribe(
      (res: IAddress) => {
        this._logger.info(this.constructorName, url, res);

        if (method === 'post') {
          this.resetModal(value);
          this._router.navigate(['/locations', value.location]);
          this.entry.enable();
        } else {
          this.resetModal(res);
          this.entry.enable();
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;

        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }
      }
    );
  }

  private setValidators() {
    const id = this.entry.get('id');
    const parent = this.entry.get('parent')['controls']['id'];
    const user = this.entry.get('user')['controls']['id'];

    if (this.isSubLocation) {
      parent.setValidators([Validators.required]);
      user.setValidators([]);
    } else {
      parent.setValidators([]);
      user.setValidators([Validators.required]);
    }

    if (this.isEditing) {
      id.setValidators([Validators.required]);
    } else {
      id.setValidators([]);
    }

    id.updateValueAndValidity();
    parent.updateValueAndValidity();
    user.updateValueAndValidity();
  }

  private setDefaultValues() {
    this.disableButtons = false;
    this.defaultUserValue = '';
    this.items = [];
    this.entry.patchValue({
      fileNames: [],
      files: []
    });
  }
}
