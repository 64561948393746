import { Injectable } from '@angular/core';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { ActivitiesService } from '@shared/services/activities.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationActivitiesService extends PaginationService<IActivity> {

  private static readonly DEFAULT_ASSET_ID_FILTER: IFilter = {
    key: 'asset',
    value: null
  };

  private _filtersScope: any;

  constructor(_paginable: ActivitiesService, _logger: LoggerService) {
    super(_paginable, _logger);
    this.setFilters([
      PaginationActivitiesService.DEFAULT_ASSET_ID_FILTER
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationActivitiesService.DEFAULT_ASSET_ID_FILTER
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
