import { IAction } from '@shared/interfaces/action.interface';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { ITask } from '@shared/interfaces/task.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { User } from '@shared/models/user.model';
import * as moment from 'moment'

export class Activity implements IActivity {
  public action?: Partial<IAction>;
  public checklist?: Partial<IChecklist>;
  public user?: Partial<IUser>;
  public recipient?: Partial<IUser>;
  public task?: Partial<ITask>;

  public ownerId: string;
  public ownerType: string;
  public trackableId: string;
  public trackableType: string;
  public recipientId: string;
  public recipientType: string;

  public key: string;
  public value: string;
  public description?: string;

  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(partial: Partial<IActivity>) {
    Object.assign(this, {
      ...partial,
      user:
        typeof partial?.user === 'object' && partial?.user !== null
          ? new User(partial.user)
          : partial?.user,
      recipient:
        typeof partial?.recipient === 'object' && partial?.recipient !== null
          ? new User(partial.recipient)
          : partial?.recipient
    });
  }

  get message(): string {
    switch (this.key) {
      case 'checklist.created':
        return `Checklist <b>${this.checklist?.label}</b> was created by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'checklist.meterValidated':
        return `Checklist <b>${this.checklist?.label}</b> was validated by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'checklist.reviewed':
        return `Checklist <b>${this.checklist?.label}</b> was reviewed by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'checklist.updated':
        return `Checklist <b>${this.checklist?.label}</b> was updated by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'checklist.alert':
        return `Checklist <b>${this.checklist?.label}</b> triggered an alert (NO answers) by <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'task.closed':
        return `Work Order <b>${this.task?.task}</b> was closed by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'task.completed':
        return `Work Order <b>${this.task?.task}</b> was completed by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'task.created':
        return `Work Order <b>${this.task?.task}</b> was created by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'task.deleted':
        return `Work Order <b>${this.task?.task}</b> was deleted by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      case 'task.updated':
        return `Work Order <b>${this.task?.task}</b> was updated by the user <b>${this.user?.fullName}</b> at ${moment(this.createdAt).format('lll')}`;

      default:
        return '-';
    }
  }

  get getActivity(): any {
    return {
      label: this.message, description: this.description
    };
  }
}
