import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { Location } from '@shared/models/location.model';
import { AssetsService } from '@shared/services/assets.service';
import { EmployeesService } from '@shared/services/employees.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { forkJoin, noop, Subject } from 'rxjs';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { uploaderOptions } from '@shared/helpers/upload.helper';
import { ContactsService } from '@shared/services/contacts.service';
import { AddressesService } from '@shared/services/addresses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetCategorysService } from '@shared/services/assets-categories.service';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { ITask } from '@shared/interfaces/task.interface';
import { Router } from '@angular/router';
import { PaginationAssetsService } from '@shared/services/pagination/pagination.assets.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ILibrary } from '@shared/interfaces/library.interface';
import { LibrariesService } from '@shared/services/libraries.service';
import moment from 'moment';
import { PaginationAssetGroupsService } from '@shared/services/pagination/pagination.assets-group.service';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';
import { AssetGroupsService } from '@shared/services/assets-group.service';
import { IPayload } from '@shared/interfaces/payload.interface';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-modals-asset-groups',
  templateUrl: './modals.asset-groups.component.html',
  styleUrls: ['./modals.asset-groups.component.scss'],
})
export class ModalsAssetGroupsComponent
  extends ModalDirective<IAsset>
  implements OnInit {
  @Input() public openModal: Subject<IAssetGroup>;
  @Input() public afterClose?: () => any = noop;
  @Input() public afterSubmit: (any) => any = noop;

  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  public options: UploaderOptions = uploaderOptions;
  public openTasksModal: Subject<ITask> = new Subject();

  public defaultParentLocationValue: string;
  public defaultSubLocationValue: string;
  public defaultCategoryValue: string;
  public title = String('MODALS.ASSETS.ADD.TITLE');
  public description = String('MODALS.ASSETS.ADD.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public disableButtons = Boolean(false);
  public openDeleteModal: Subject<boolean> = new Subject();
  public isEditing = Boolean(false);
  public id = String('');
  public assetForm: any = {};
  public isTenant = Boolean(false);
  public fgSelected: FormGroup;
  public categoryFilters: any[] = [];
  public uploadImages: any[] = [];
  public items: any[] = [];
  public libraryList: any[] = [];
  public file: File;
  public files: NgxFileDropEntry[] = [];
  public filesToUpload: NgxFileDropEntry[] = [];
  public showDeviceFiles = Boolean(false);
  public isLoading = Boolean(true);
  public isDisabled = Boolean(false);
  public loaded = Boolean(false);
  public entry: FormGroup;
  public timezone = String('');
  public timezoneLocation = String('');
  public currentUserId = String('');

  public bsConfig: any = {
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'year',
    maxDate: moment().toDate()
  };
  public config: any = {
    class: 'modal-lg'
  };
  public closeModal: Subject<any> = new Subject<any>();

  private readonly constructorName: string = String(this.constructor.name);
  private blob: Blob;

  constructor(
    public readonly _assetGroups: AssetGroupsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService,
    private readonly _paginationAssetGroups: PaginationAssetGroupsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router,
    public dom: DomSanitizer
  ) {
    super();
    // this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    // this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
    // this.onGetCategoryName = this.onGetCategoryName.bind(this);
    // this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }


  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((a: IAssetGroup) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());

      if (a?.id) {
        this.id = a.id;
        this.isEditing = true;
        this.title = 'MODALS.ASSET_GROUPS.EDIT.TITLE';
        this.description = 'MODALS.ASSET_GROUPS.EDIT.DESCRIPTION';
        this.deleteKey = 'BUTTONS.DELETE';

        this.entry.patchValue({
          ...a,
        });
        this.isLoading = true;
        const url = `GET /contacts/${this.id}/documents`;
      } else {
        this.items = [];
        this.title = 'MODALS.ASSET_GROUPS.ADD.TITLE';
        this.description = 'MODALS.ASSET_GROUPS.ADD.DESCRIPTION';

        this.deleteKey = null;

      }
    });

  }

  public openUpload() {
    const el = document.getElementsByClassName('upload-files-tasks')[0] as any;
    el?.click();
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
    this.id = '';
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      let method = 'post';
      let url = `${method.toUpperCase()} /assets`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /assets/${value.id}`;
      }
      value = this.entry.getRawValue();
      this.createAndUpdateAssetGroup(value, method, url);
    }
  }

  public createAndUpdateAssetGroup(value, method, url) {
    this.disableButtons = true;
    this.entry.disable();

    this._assetGroups[method](value).subscribe(
      (res: IAssetGroup) => {
        this._logger.info(this.constructorName, url, res);
        this.entry.enable();
        this.entry.reset();
        this.disableButtons = false;
        this.id = res.id;
        if (method === 'post') {
          this._toasts.success('Asset successfully created!');
        } else if (method === 'patch') {
          this._toasts.success('Asset successfully updated!');
        }

        this._paginationAssetGroups.reloadPage(1);
        this.createForm();
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
        this.disableButtons = false;
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      name: ['', [Validators.required]],
      description: ['', []],
    });
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /asset-groups/${id}`;

    this._assetGroups.delete(id).subscribe(
      (res: IAssetGroup) => {
        this._logger.info(this.constructorName, url, res);
        this._paginationAssetGroups.reloadPage(1);
        this.reset();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }

  private reset() {
    this.disableButtons = false;
    this.entry.enable();
    this.resetModal();
  }

}
