import { Injectable } from '@angular/core';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';
import { AssetGroupsService } from '../assets-group.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationAssetGroupsService extends PaginationService<IAssetGroup> {

  private _filtersScope: any;

  constructor(_paginable: AssetGroupsService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
