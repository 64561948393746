import { environment } from '@environment/environment';
import { itemTypes, itemValues } from '@shared/helpers/types.helper';
import { IItemGroup } from '@shared/interfaces/item-group.interface';
import { IItemOption } from '@shared/interfaces/item-option.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { ItemGroup } from '@shared/models/item-group.model';

export class Item implements IItem {
  public id: string;
  public template: Partial<ITemplate>;
  public itemGroup: Partial<IItemGroup>;

  public label: string;
  public type: number;

  public value?: string;
  public defaultValue?: string;
  public order: number;
  public meterType?: string;
  public isValidated?: boolean;
  public meterUnit?: string;
  public comment?: string;
  public pictures?: string[];
  public actions?: string[];
  public options?: IItemOption[];
  public propertyId?: string;
  public meterId?: string;
  public operation?: string;
  public factor?: number;

  constructor(partial: Partial<IItem>) {
    Object.assign(this, {
      ...partial,
      itemGroup:
        typeof partial?.itemGroup === 'object'
          ? new ItemGroup(partial.itemGroup)
          : partial?.itemGroup
    });
  }

  public get typeLabel(): string {
    return itemTypes.find((i) => i.value === this.type).label;
  }

  public get valueLabel(): string {
    if (this.type > 0) {
      return this.value;
    }

    return itemValues.find((t: any) => t.value === this.value || '0').label;
  }

  public get defaultValueLabel(): string {
    if (this.type > 0) {
      return this.defaultValue;
    }

    return itemValues.find((t: any) => t.value === this.defaultValue)?.label;
  }
}
