import { locations } from '@shared/serializers/locations.serializer';
import { assetsCategory } from '@shared/serializers/assets-category.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { libraries } from './libraries.serializer';

export const assetGroups = {
  type: 'asset',
  attributes: [
    'name',
    'description',
    'company',
    'isDeleted',
  ]
};

export const AssetGroupsSerializer = getSerializer(assetGroups);
