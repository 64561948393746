import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';
import { assets } from '@shared/serializers/assets.serializer';
import { recurringTasks } from '@shared/serializers/recurring-tasks.serializer';
import { libraries } from './libraries.serializer';
import { assetsCategory } from './assets-category.serializer';
import { assetGroups } from './asset-groups.serializer';
export const tasks = {
  type: 'task',
  attributes: [
    'user',
    'createdBy',
    'asset',
    'recurringTask',
    'location',
    'task',
    'notes',
    'pictures',
    'documents',
    'isSendEmailSelected',
    'description',
    'createdAt',
    'completedAt',
    'updatedAt',
    'checklistId',
    'priority',
    'recurrence',
    'unit',
    'status',
    'userType',
    'startDate',
    'dueDate',
    'hasStartDate',
    'hasStartTime',
    'hasDueDate',
    'hasDueTime',
    'customizeDates',
    'weeklyDays',
    // 'price',
    'monthlyDays',
    'yearlyMonths',
    'isOverdue',
    'category',
    'assetGroups'
  ],
  createdBy: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  recurringTask: {
    ref: 'id',
    attributes: recurringTasks.attributes,
    included: false
  },
  location: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  asset: [{
    ref: '_id',
    attributes: assets.attributes,
    included: true,
    multiple: true
  }],
  category: {
    ref: 'id',
    attributes: assetsCategory.attributes,
    included: true
  },
  assetGroups: [{
    ref: 'id',
    attributes: assetGroups.attributes,
    included: false
  }]
};

export const TasksSerializer = getSerializer(tasks);
