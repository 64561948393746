import { locations } from '@shared/serializers/locations.serializer';
import { assetsCategory } from '@shared/serializers/assets-category.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { libraries } from './libraries.serializer';
import { assetGroups } from './asset-groups.serializer';

export const assets = {
  type: 'asset',
  attributes: [
    'name',
    'serial',
    'description',
    'documents',
    'modelName',
    'tag',
    'make',
    'parentLocation',
    'category',
    'subLocation',
    'year',
    'lifeExpectancy',
    'group',
  ],
  parentLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: true
  },
  group: [{
    ref: 'id',
    attributes: assetGroups.attributes,
    included: true
  }],
  category: {
    ref: 'id',
    attributes: assetsCategory.attributes,
    included: true
  },
  subLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: true
  },
  // documents: {
  //   ref: 'id',
  //   atributes: libraries.attributes,
  //   included: true
  // }
};

export const AssetsSerializer = getSerializer(assets);
