import { environment } from '@environment/environment';
import { ITask } from '@shared/interfaces/task.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { User } from '@shared/models/user.model';
import { IAsset } from '@shared/interfaces/asset.interface';
import { ILibrary } from '@shared/interfaces/library.interface';
import moment from 'moment';

export class Asset implements IAsset {
  id: string;
  name: string;
  description: string;
  modelName?: string;
  serial?: string;
  tag?: string;
  make?: string;
  year?: number;
  lifeExpectancy?: number;
  documents?: any[];
  parentLocation?: ILocation;
  category?: IAsset;
  subLocation?: ILocation;
  group?: string[];

  constructor(partial: Partial<IAsset>) {
    Object.assign(this, {
      ...partial,
      parentLocation:
        partial?.parentLocation !== null &&
          typeof partial?.parentLocation === 'object'
          ? new Location(partial.parentLocation)
          : partial?.parentLocation,
      subLocation:
        partial?.subLocation !== null &&
          typeof partial?.subLocation === 'object'
          ? new Location(partial.subLocation)
          : partial?.subLocation,
      category:
        partial?.category !== null &&
          typeof partial?.category === 'object'
          ? new Location(partial.category)
          : partial?.category,
    });
  }

  get getReplacementYear() {
    const obj: any = {
      msg: this.year + this.lifeExpectancy,
      year: this.year,
      lifeExpectancy: this.lifeExpectancy,
    };

    if (this.year + this.lifeExpectancy < moment().year()) {
      obj.msg = `${this.year + this.lifeExpectancy} <span container="body" ><i class="lnr lnr-notification text-danger flash"></i></span>`;
    }

    return obj;
  }

}
